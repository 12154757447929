import * as React from "react"
import {useState, useEffect} from "react"

// components
import Header from "../components/header"
import Banner from "../components/banner"
import MediaBox from "../components/media-box"
import MediaEmbed from "../components/media-embed"
import Footer from "../components/footer"
import SEO from "../components/seo"

import { Container, Button, Modal } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'
import { renderToString } from 'react-dom/server';
import { Parallax, Background } from 'react-parallax';

// images

import play from '../images/play.png'


import grayLinesBG from '../images/background_stack.jpg'


///////


import * as styles from './page.module.scss'

import { usePage } from "../hooks/home-page"

const Page = ({ pageContext }) => {
  const post = pageContext
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const videoBackgroundFluidImageStack = [
    post.acfPageHeader.videoBoxImage.localFile.childImageSharp.fluid,
    `linear-gradient(0deg,rgba(0,0,0,.3),rgba(0,0,0,.8))`,
  ].reverse()

  useEffect(() => {
      let blar = document.getElementById('content');
      if (blar !== null) {
          blar.outerHTML = blar.innerHTML;
      }
  });

  return (
    <main>
        <style>
          {`.page-content-wrap .row {
            width: calc(100% + 30px);
          }
          .media-box-content-wrap .col > div > div > h4,
          .media-box-content-wrap .col > div > div > h2,
          .media-box-content-wrap .col > div > div > hr {
              display: none;
          }
          `}
        </style>
        <SEO
          title={post.title}
          description={post.acfSEO.metaDescription}
          image={ (post.acfPageHeader.bannerBackgroundImage.localFile.childImageSharp.fluid.src !== undefined ) ? post.acfPageHeader.bannerBackgroundImage.localFile.childImageSharp.fluid.src : null }

          />
        <Header />

        <div className="bg-light" style={{backgroundImage: "url(" + grayLinesBG + ")", backgroundSize: '100%'}}>
          <Banner
            title={post.acfPageHeader.headerTitle}
            subtitle={post.acfPageHeader.headerSubtitle}
            bodytext={post.acfPageHeader.headerBodyText}
            backgroundImage={post.acfPageHeader.bannerBackgroundImage}
            backgroundVideoUrl={post.acfPageHeader.backgroundVideoUrl}
          >
          </Banner>
          <div style={{postion:'relative', marginTop:'-25vh'}}>
            <MediaBox
              orientation="right-50"
              title={post.acfPageHeader.videoBoxTitle}
              subtitle={post.acfPageHeader.videoBoxSubtitle}
              videoImage={post.acfPageHeader.videoBoxImage}
              videoUrl={post.acfPageHeader.videoBoxVideoUrl}
              >
              <div dangerouslySetInnerHTML={{__html: post.acfPageHeader.videoBoxBodyText}} className="pb-4" />
                <div className="d-lg-none mb-4">
                  <BackgroundImage
                    className="d-flex w-100"
                    Tag="div"
                    fluid={videoBackgroundFluidImageStack}
                    backgroundColor={`#040e18`}
                    style={{minHeight: "225px"}}
                  >
                    <div className="d-flex align-items-center justify-content-center w-100">
                      <button className="d-block h-100 w-100" style={{background: "none", border: "0"}} onClick={handleShow}>
                        <img src={play} alt="Play" />
                      </button>
                    </div>
                    <Modal show={show} onHide={handleClose} centered={true} size="xl">
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <MediaEmbed videoUrl={post.acfPageHeader.videoBoxVideoUrl}  autoplay={true} />
                      </Modal.Body>
                    </Modal>
                  </BackgroundImage>
                </div>
              <Button className="w-100 " href="/current-openings" style={{borderRadius:'20px'}}>View Current Openings</Button>
            </MediaBox>
          </div>
          <Container className={`bg-white p-4 ${styles.pageContent}`}>
            {/*<div dangerouslySetInnerHTML={{__html: post.content}} />*/}
            <div className="page-content-wrap py-4 d-flex flex-wrap">
              <div id="content" dangerouslySetInnerHTML={{__html: post.content}}></div>
              {/*{contentResult}*/}

              <div className="order-3 w-100 media-box-content-wrap mb-5">
                <MediaBox
                    orientation="left"
                    // title="Special Events"
                    // subtitle={post.acfPageHeader.videoContentTitle}
                    videoImage={post.acfPageHeader.videoContentImage}
                    videoUrl={post.acfPageHeader.videoContentVideoUrl}
                    // bgColor="bg-light"
                  >
                    <div className="media-box-content">
                      <h3 className="text-uppercase border-bottom pb-1">{post.acfPageHeader.videoContentTitle}</h3>
                      <hr/>
                      <p>{post.acfPageHeader.videoContentBodyText}</p>
                    </div>
                  </MediaBox>
              </div>
            </div>
            {/*<ContentBlockFull/>*/}
          </Container>
        </div>
        <Footer />
    </main>
  )
}

export default Page
